import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdvs } from "../../redux/actions/advs.action";

const TourList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAdvs());
  }, []);
  const { advs } = useSelector((state) => state.advs);

  return (
    <div className="tour-list-area pd-top-120 viaje-go-top">
      <div className="container">
        <div className="row">
          {advs &&
            advs.map((n) => (
              <div className="col-lg-6">
                <div className="single-destinations-list style-four rtl">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage: `url(http://portal.hipz.edu.ly/${n.image}`,
                    }}
                    src={"http://portal.hipz.edu.ly/" + n.image}
                  />
                  <div className="details text-right">
                    <h4 className="title">
                      <Link to={`/advs/${n._id}`}>{n.title}</Link>
                    </h4>
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{ __html: n.discription }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TourList;
