export const GET_NEWSS = "GET_NEWSS";
export const GET_NEWS = "GET_NEWS";
export const GET_ADVS = "GET_ADVS";
export const ERROR_ADV = "ERROR_ADV";
export const GET_ADV = "GET_ADV";
export const CHANGE_LANG = "CHANGE_LANG";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const ERROR_DEPARTMENT = "ERROR_DEPARTMENT";
export const ERROR_NEWS = "ERROR_NEWS";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_MEMBERS = "GET_MEMBERS";
export const ERROR_MEMBER = "ERROR_MEMBER";
export const GET_SUBJECTS = "GET_SUBJECTS";
export const ERROR_SUBJECT = "ERROR_SUBJECT";

export const GET_GRADUTIONYEARS = "GET_GRADUTIONYEARS";
export const GET_STUDENTS = "GET_STUDENTS";
export const ERROR_STUDENT = "ERROR_STUDENT";
export const FILTER_STUDENTS = "FILTER_STUDENTS";
export const ERROR_GRADUTIONYEAR = "ERROR_GRADUTIONYEAR";

export const GET_RESULT = "GET_RESULT";

// export const URL = "http://localhost:3000";
export const URL = "https://portal.hipz.edu.ly/";
