export const Title = {
  ar: "المعهد العالي لعلوم البترول والغاز الطبيعي",
  en: " Institute  Petroleum and Natural Gas Sciences",
  fr: "",
};
export const Main = {
  ar: "الرئيسية ",
  en: "Main Page",
  fr: "",
};
export const Departments = {
  ar: "الأقسام العلمية",
  en: "Departments",
  fr: "",
};
export const About = {
  ar: "عن المعهد",
  en: "About us",
  fr: "",
};
export const Contact = {
  ar: "اتصل بنا",
  en: "Contact Us",
  fr: "",
};
export const platform = {
  ar: "المنصة الالكترونية",
  en: "E-learning platform",
  fr: "",
};

export const visionTitle = {
  ar: "رؤيتنا",
  en: "Our vision",
  fr: "",
};
export const visionDes = {
  ar: "مهندسين رياديين في مجال برامج وتقنيات هندسة النفط والغاز",
  en: "  Leadership engineers in the field of oil and gas  programs and technologies",
  fr: "",
};

export const messageTitle = {
  ar: "رسالتنا",
  en: "Our message",
  fr: "",
};

// export const More = {
//   ar: "",
//   en: "",
//   fr: "",
// };

export const intro = {
  ar: "نبذة عن المعهد",
  en: "Introduction",
  fr: "",
};

export const messageDes = {
  ar: "تخريج مهندسين ذو قدرات عالية تلبي احتياجات سوق العمل في مجال النفط والغاز .",
  en: "Graduating engineers with high capabilities that meet the needs of the labor market in the field of oil and gas.",
  fr: "",
};
export const ValuablesTitle = {
  ar: "قيمنا",
  en: "Our Valuables",
  fr: "",
};

export const ValuablesDes = {
  ar: "الإخلاص – التميز – الابتكار",
  en: "Sincerity - Excellence - Innovation",
  fr: "",
};

export const What = {
  ar: `<h2 class="title">
ماذا تعرف عن المعهد <br />
</h2>
<p class="text-justify">
  يعتبر المعهد العالي لعلوم البترول والغاز الطبيعي احد المعاهد
  العليا الخاصة بمدينة الزاوية والتي تمنح شهادة الدبلوم العالي
  في مجال التخصصات الهندسية المتعلقة بالاستكشاف والانتاج النفطي
  <br />
  تم اعتماد تأسيس المعهد بقرار اذن المزاولة رقم (29) لسنة 2021م
  الصادر من إدارة التعليم التقني والفني الخاص بوزارة التعليم
  التقني والفني كما تحصل المعهد على الترخيص الفني من المركز
  الوطني لضمان جودة واعتماد المؤسسات التعليمية والتدريبية بقرار
  من مدير المركز رقم 107 لسنة 2021 في برنامجي الهندسة النفطية
  والإدارة الهندسية .
  <br />
  ويعتمد نظام الدراسة بالمعهد على نظام الفصل الدراسي بعدد ستة
  فصول دراسية بواقع 124 وحدة دراسية موزعة وفق الخطة الدراسية علي
  مقررات علوم إنسانية واساسية عامة وهندسية أساسية وهندسية تخصصية.
</p> `,
  en: `<h2 class="title">
  What do you know about the institute?<br />
  </h2>
  <p class="text-justify">The High Institute of Petroleum and Natural Gas Sciences is one of the higher institutes for the city of Zawiya, which grants a higher diploma in the field of engineering disciplines related to oil exploration and production.
    <br />The institute has obtained a technical license from the National Center for Quality Assurance and Accreditation of Educational and Training Institutions, by of the Director of the Center No. 107 of 2021 in the petroleum engineering and engineering management programs.
    <br />
    The  (education roles)  are based on the semester system with six semesters of 124 academic units distributed according to the study plan on humanities, general, basic, basic engineering, and specialized engineering courses.
  </p> `,
  fr: "",
};

export const More = {
  ar: " تعرف اكثر",
  en: "Read More .. ",
  fr: "",
};

export const Director = {
  ar: `<p class="sub-title">كلمة</p>
  <h3 class="location-name">المدير العام</h3>
  <p> بسم الله والصلاة والسلام على رسول الله </p>

  <p class="text-justify">
    في البداية لا يسعني الا ان ابارك افتتاح المعهد العالي لعلوم
    البترول والغاز الطبيعي بالزاوية بعد استكمال اجراءاته القانونية
    من خلال الحصول على قرار الترخيص الفني من مركز ضمان الجودة
    وكذلك الحصول على اذن المزاولة من وزارة التعليم التقني وبالتالي
    فتح المعهد فرصة لطلاب الثانوية العامة باستكمال دراستهم في مجال
    هندسة النفط والإدارة الهندسية وكذلك لخريجي المعهد النوعي
    للصناعات النفطية باستكمال دراستهم العليا للحصول على درجة
    الدبلوم العالي وكذلك فتح فرصة لطلاب الجامعات بإجراء معادلات
    لهم والالتحاق بالدراسة بالمعهد
    <br />
    ومن هنا نرحب بجميع الملتحقين بالمعهد من طلاب وموظفين وأعضاء
    هيئة تدريس ونتمنى لهم النجاح في المهمة التي أوكلت لهم ونتمنى
    التفوق والنجاح لطلابنا والوصول الى تحقيق أهدافهم التي رسموها
    لحياتهم المهنية والعملية، فمن أولويات الأهداف التي وضعت لهذا
    المعهد تخريج دفعات مؤهلة وذلك لخدمة المجتمع برؤية واستراتيجية
    واضحة وسباقة ونحاول فتح قنوات عمل مع المعاهد المناظرة بالداخل
    والخارج كما نسعــــــــــى جاهدين من اجل ربط طلابنا مع سوق
    العمل في ليبيا وتوفير فرص عمل واسعة لهم من اجل الانفتاح على
    المجتمع والمؤسسات في الداخل والخارج .
  </p>`,
  en: `<p class="sub-title">Speech</p>
  <h3 class="location-name"> of General Director</h3>
  <p> In the name of God, prayer, and peace be upon the Messenger of Allah</p>

  <p class="text-justify">
  in the beginning, I can only bless the opening of the Higher Institute of Petroleum and Natural Gas Sciences in Zawia after completing its legal procedures by obtaining a technical licensing decision from the Quality Assurance Center, as well as obtaining a practice permit from the Ministry of Technical Education, and thus opening the institute an opportunity for high school students to complete their studies in the field Petroleum engineering and engineering management, as well as for graduates of the Specific Institute for Petroleum Industries to complete their postgraduate studies to obtain a higher diploma, as well as opening an opportunity for university students to make equivalencies for them and join the study at the institute
    <br />
    Hence, we welcome all those enrolled in the institute, including students, employees, and faculty members, and we wish them success in the mission entrusted to them. We wish excellence and success for our students and the achievement of their goals that they set for their professional and practical lives. One of the priorities of    set for this institute is graduating qualified batches in order to serve the community with a clear vision and strategy. We are proactive and try to open work channels with the corresponding institutes at home and abroad. We also strive to link our students with the labor market in Libya and provide them with wide job opportunities in order to open up to society and institutions at home and abroad.
  </p>`,
  fr: "",
};

export const boss = {
  ar: `
  <h6> د.فطيمة مسعود</h6>
  <p>مدير عام المعهد</p>
`,
  en: ` 
  <h6>Dr. Fatima Masoud</h6>
  <p>Director of the Institute</p>
`,
  fr: "",
};

export const intro3 = {
  ar: `<h2 class="title">
       <span>أهم ما</span> يميز معهدنا
       </h2>`,
  en: `<h2 class="title">
  Our Institute <span>Distinctions<span>
  </h2>`,
  fr: "",
};

export const classes = {
  ar: ` <h4 class="intro-title"> القاعات الدراسية والمكتبة</h4>
  <p class="text-justify">
    يحتوي المعهد على قاعات دراسية مجهزة وذلك لتوفير المناخ التعليمي
    المناسب للطالب وكذلك مكتبة علمية مجهزة باحدث الاصدارات من الكتب
    والمصادر التعليمية المختلفة.
  </p>`,
  en: ` <h4 class="intro-title"> Classrooms and Library </h4>
  <p class="text-justify">
   
The institute has well equipped and convert classrooms that will help the students to learn and understand the courses. It also has al scientific library equipped with all kind of books and papers of different editions.
  </p>`,
};

export const Tools = {
  ar: `<h4 class="intro-title">الوسائل التعليمية</h4>
  <p class="text-justify">
    يمتلك المعهد مجموعة من المعامل المجهزة بالأدوات والمواد والأجهزة
    التعليمية المختلفةوذلك لنقل لنقل المتعلم من واقع الخبرة المجردة
    الى واقع الخبرة العملية الملموسة للوصول الى التعلم الفعال و
    توفير اجواء مشوقة ومشجعة بحيث يتولد للطالب الرغبة بالتقدم نحو
    تعلم أفضل ..
  </p>`,
  en: `<h4 class="intro-title"> Education Tools </h4>
  <p class="text-justify">
      The Institute has numbers of well-equipped laboratories in order to help the instructors to transfer the educational experiences to the students.
  </p>`,
};

export const ELearning = {
  ar: `<h4 class="intro-title">التعليم الالكتروني</h4>
  <p class="text-justify">
    يمتلك المعهد منصة للتعليم المدمج، وتتيح هذه المنصة الإلكترونية
    للمستخدمين إمكانية الوصول إلى الفصول الافتراضية. كما توفر للطالب
    تجربة تعليمية جذابة من خلال ملفات الفيديو والصوت والصورة والنصوص
    والعروض التقديمية وملفات PDF.
  </p>`,
  en: `<h4 class="intro-title"> E-Learning </h4>
  <p class="text-justify">
  The Institute has a merged web site designed to serve the user to connect to the virtual classrooms. It introduces an attractive a new education method to the students through the video, audio, images and presentations plus the PDF files
  </p>`,
};

export const Address = {
  ar: "الزاوية - شارع جمال عبد الناصر -مدرسة الشيباني بن نصرات",
  en: "Al-Zawiya - Jamal Abdel Nasser Street - Al-Shaibani Bin Nasrat School",
};
