import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import { About, Address, Contact, Main, Title } from "./sections";

const Footer_v1 = ({ lang }) => {
  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <footer
      className={
        lang === "ar" ? "footer-area text-right" : "footer-area text-left"
      }
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/2.png)",
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="footer-widget widget">
              <div className="about_us_widget">
                <Link to="/" className="footer-logo">
                  <img
                    src={publicUrl + "assets/img/logo.png"}
                    alt="footer logo"
                  />
                </Link>
                <p className="text-justify">
                  {lang === "ar"
                    ? `يعتبر المعهد العالي لعلوم البترول والغاز الطبيعي احد المعاهد
                  العليا الخاصة بمدينة الزاوية والتي تمنح شهادة الدبلوم العالي
                  في مجال التخصصات الهندسية المتعلقة بالاستكشاف والانتاج النفطي`
                    : `The High Institute of Petroleum and Natural Gas Sciences is one of the higher institutes for the city of Zawiya, which grants a higher diploma in the field of engineering disciplines related to oil exploration and production`}
                </p>
                <ul className="social-icon">
                  <li>
                    <a
                      className="facebook"
                      href="https://www.facebook.com/oil.and.gas.of.zawia218"
                      target="_blank"
                    >
                      <i className="fa fa-facebook  " />
                    </a>
                  </li>
                  <li>
                    <a className="twitter" href="#" target="_blank">
                      <i className="fa fa-twitter  " />
                    </a>
                  </li>
                  <li>
                    <a className="pinterest" href="#" target="_blank">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget ">
              <div className="widget-contact">
                <h4 className="widget-title">{Contact[lang]}</h4>
                <p>
                  <i className="fa fa-map-marker" />
                  <span>{Address[lang]}</span>
                </p>
                <p className="location">
                  <i className="fa fa-envelope-o" />
                  <span>info@hipz.edu.ly</span>
                </p>
                <p className="telephone">
                  <i className="fa fa-phone base-color" />
                  <span>00218927630338</span>
                </p>
                <p className="telephone">
                  <i className="fa fa-phone base-color" />
                  <span>00218946757031</span>
                </p>
                <p className="telephone">
                  <i className="fa fa-phone base-color" />
                  <span>00218917419804</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="footer-widget widget">
              <h4 className="widget-title">
                {lang === "ar" ? "الروابط" : "Links"}
              </h4>
              <ul className="widget_nav_menu  viaje-go-top">
                <li>
                  <Link to="/">{Main[lang]}</Link>
                </li>
                <li>
                  <Link to="/about">{About[lang]}</Link>
                </li>

                <li>
                  <Link to="/contact">{Contact[lang]}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget widget">
              {/* <h4 className="widget-title">معرض صور</h4>
                <ul className="widget-instagram-feed">
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/1.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/2.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/3.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/4.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/5.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={publicUrl + "assets/img/instagram/6.png"}
                        alt="img"
                      />
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-inner">
        <div className="copyright-text">
          © {new Date().getFullYear()} {Title[lang]}
          <a href="https://hipz.edu.ly/#/" target="_blank">
            <i className="fa fa-heart" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v1;
