import React from "react";
import { useSelector } from "react-redux";
import {
  What,
  intro,
  visionTitle,
  visionDes,
  messageDes,
  messageTitle,
  ValuablesTitle,
  ValuablesDes,
} from "../global-components/sections";
const About = ({ lang }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="about-section pd-top-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div
              className={
                lang === "ar"
                  ? "section-title mb-lg-0 text-right"
                  : "section-title mb-lg-0 text-left"
              }
              style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
            >
              <h2 className="title"> {intro[lang]}</h2>

              <div dangerouslySetInnerHTML={{ __html: What[lang] }}></div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <div className="thumb about-section-right-thumb">
              <img src={publicUrl + "assets/img/others/9.png"} alt="img" />
              <img
                className="about-absolute-thumb"
                src={publicUrl + "assets/img/others/10.png"}
                alt="img"
              />
            </div>
          </div>
        </div>
        <div
          className={
            lang === "ar"
              ? "section-title mb-lg-0 text-right"
              : "section-title mb-lg-0 text-left"
          }
          style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
        >
          <h2 className="title m-2"> {visionTitle[lang]}</h2>
          <p className="text-justify m-3">{visionDes[lang]} </p>
          <h2 className="title m-2"> {messageTitle[lang]}</h2>
          <p className="text-justify m-3">{messageDes[lang]} </p>
          <h2 className="title m-2"> أهدافنا</h2>
          <ul>
            <li> مساعدة الطلاب على استخدام التقنية الحديثة في مجال النفط </li>
            <li> مساعدة وتطوير الطلاب بمهارات البرمجة المختلفة .</li>
            <li>
              {" "}
              تقديم خدمات واسعة تلبي حاجة المجتمع في مجال هندسة النفط والغاز .
            </li>
            <li> المساهمة في المناشط العلمية المحلية والعالمية .</li>
            <li> استخدام التقنيات الحديثة في استراتيجيا التدريسية .</li>
          </ul>
          <h2 className="title text-center m-2"> {ValuablesTitle[lang]}</h2>
          <h3 className="text-center m-3"> {ValuablesDes[lang]} </h3>
          <h2 className="title m-2"> التخصصات الهندسية المعتمدة بالمعهد</h2>
          <h3>
            <ol>
              <li>. الهندسة النفطية </li>
              <li>. الإدارة الهندسية </li>
            </ol>
          </h3>

          <h2 className="title m-2"> إدارة المسجل العام</h2>
          <p className="text-justify m-3">
            مكتب المسجل العام من مكاتب الرئيسية والمهمة بكل مؤسسة تعليمية
            وتدريبية لدوره الرئيسي في العلمية التعليمية والتدريبية .
            <br />
            وبناءًا على قرار رئيس الهيئة الوطنية للتعليم التقني والفني رقم (797)
            لسنة 2017 بشأن اعتماد الهيكل التنظيمي للمعاهد التقنية العليا فإن
            مكتب المسجل العام بالمعهد تتبعه عدة أقسام وهي :-
            <ol>
              <li>. مكتب الدراسة والامتحانات </li>
              <li>. وحدة التسجيل والقبول </li>
              <li>. وحدة شؤون الطلبة والنشاط العام </li>
              <li>. وحدة الخريجين . .</li>
            </ol>
          </p>

          <h2 className="title m-2">
            {" "}
            • شروط القبول والمستندات المطلوبة للتسجيل{" "}
          </h2>
          <h3>أولاً :- شروط القبول ..</h3>
          <ol>
            <li>
              . ان يكون المتقدم حاصلاً على الشهادة الثانوية العامة القسم العلمي
              أو ما يعادلها .{" "}
            </li>
            <li>. ان يكون المتقدم حسن السيرة والسلوك </li>
            <li>
              . بالنسبة لغير الليبيين يشرط ان يكون لديهم إقامة اعتيادية في ليبيا{" "}
            </li>
            <li>. ان يجتاز المتقدم استبان المقابلة الشخصية </li>
          </ol>
          <h3>ثانياً :- المستندات المطلوبة للتسجيل ..</h3>
          <ol>
            <li>. طلب كتابي .</li>
            <li>. الشهادة الثانوية العامة قسم العلمي أو ما يعادلها </li>
            <li>. شهادة ميلاد من السجل المدني</li>
            <li>. شهادة إقامة من المسجل المدني </li>
            <li>. شهادة حسن سير والسلوك </li>
            <li>. الشهادة الصحية </li>
            <li>. عدد أربع صور شخصية مقاس 4*3 </li>
            <li>. صورة من جواز السفر </li>
            <li>. ملف معلق </li>
          </ol>
          <h3>• الانتقال إلى المعهد من معاهد تقنية مناظرة أو كليات هندسية </h3>
          <p className="text-justify m-3">
            الراغبين في الانتقال والمعادلة للدراسة بالمعهد عليهم التقدم بطلب
            كتابي مرفق بكشف درجات معتمد (اصلي) مع مفردات المنهج من جهة الدراسة
            السابقة
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
