import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNews } from "../../redux/actions/news.action";

const HolidayFun = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
  }, []);
  const { newss } = useSelector((state) => state.news);
  return (
    <div
      className="holiday-plan-area tp-holiday-plan-area mg-top-96"
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        direction: "rtl",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-9">
            <div className="section-title text-center">
              <h2
                className="title wow animated fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.1s"
              >
                {" "}
                الاخبار و النشاطات
              </h2>
              <p
                className="wow animated fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.2s"
              >
                تابعوا اخر نشاطات واخبار المعهد
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {newss &&
            newss.map((n) => (
              <div className="col-lg-3 col-sm-6">
                <div
                  className="single-destinations-list style-two wow animated fadeInUp text-right"
                  data-wow-duration="0.4s"
                  data-wow-delay="0.1s"
                >
                  <div className="thumb">
                    <Link to={`/news/${n._id}`}>
                      <img
                        src={"http://portal.hipz.edu.ly/" + n.image}
                        alt="list"
                      />
                    </Link>
                  </div>
                  <div className="details">
                    <Link to={`/news/${n._id}`}>
                      <h4 className="title">{n.title}</h4>
                    </Link>
                    <p
                      className="content text-justify"
                      dangerouslySetInnerHTML={{
                        __html: n.discription.substr(0, 150),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <h3 className=" text-center">
          <Link className="btn btn-gray text-right" to="/news">
            المزيد من الاخبار
            <i className="la la-arrow-left" />
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default HolidayFun;
