import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../redux/actions/department.action";

const BlogSection = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartments());
  }, []);
  const { departments } = useSelector((state) => state.departments);
  return (
    <div
      className="blog-area pd-top-120 viaje-go-top"
      style={{ direction: "rtl" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="single-blog single-blog-wrap style-two">
              <div className="thumb single-blog-left-wrap">
                <img src={publicUrl + "assets/img/blog/10.png"} alt="blog" />
              </div>
              <div className="single-blog-details single-blog-right-wrap text-right">
                <h4 className="title">الاقسام العلمية بالمعهد</h4>
                <p className="content text-justify">
                  تستقبل الاقسام العلمية طلبة خريجي الشهادة الثانوية
                  بفروعها العلمي والادبي والمهني ( التجاري و الصناعي ) حيث تبلغ فترة
                  الدراسة بالمعهد  سنتان يتخللهما التدريب المنهجي والتدريب العملي
                  في بيئة العمل المستقبلية للطلبة .
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              {departments &&
                departments.map((department) => (
                  <div className="col-sm-6">
                    <div className="single-blog style-three">
                      <div className="thumb">
                        <img
                          src={"http://portal.hipz.edu.ly/" + department.image}
                          alt={department.title}
                        />
                      </div>
                      <div className="single-blog-details-wrap">
                        <div className="single-blog-details text-center">
                          <h4 className="title">{department.title}</h4>
                          <Link
                            className="btn btn-yellow"
                            to={`/departments/${department.Url}`}
                          >
                            <span>
                              المزيد
                              <i className="la la-arrow-right" />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
