import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import DestinationDetails from "./section-components/destination-details";
import Subscribe from "./section-components/subscribe";
import Footer from "./global-components/footer";
import { useDispatch, useSelector } from "react-redux";
import { getoneDepartment } from "../redux/actions/department.action";
import Members from "./section-components/members";
import { getAdvs } from "../redux/actions/advs.action";

const DestinationDetailsPage = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getoneDepartment(match.params.id));
  }, [match]);
  const { department } = useSelector((state) => state.departments);

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle={department && department.title}
        image={department && department.image}
      />
      {department && <DestinationDetails department={department} />}
      <Members department={department} />
      <Footer />
    </div>
  );
};

export default DestinationDetailsPage;
