import React from "react";
import { Title } from "../global-components/sections";

const VideoBanner = ({ lang }) => {
  return (
    <div className="video-banner-area youtube-bg">
      <div className="video-banner-content-wrap">
        <div className="container">
          <div className="video-banner-content text-center">
            <h2>{Title[lang]}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
