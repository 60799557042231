import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Team extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="team-newslater-bg mt-4"
        
        style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/4.png)", direction: "rtl", }}
      >
        {/* team area end */}
        <div className="team-area pd-top-70">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2 className="title">أعضاء مجلس الادارة</h2>
                  <p>
                    فريق عمل متميز لادارة وخدمة المعهد
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/ftima.jpg"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">فطيمة الشيباني مسعود</a>
                  </h3>
                  <span>المدير العام</span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/nori.jpg"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">نوري محمد فحيل بوم</a>
                  </h3>
                  <span>المسجل العام</span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/hamza.jpg"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">حمزة محمد بشينة</a>
                  </h3>
                  <span>مدير الشؤون الادارية والمالية</span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/jamal.jpg"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">جمال عبدالخالق معتوق</a>
                  </h3>
                  <span>مدير مكتب شؤون الطلبة</span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">محمد عبدالمجيد قباصة</a>
                  </h3>
                  <span>مدير مكتب الجودة</span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">عماد يوسف الاشخم</a>
                  </h3>
                  <span></span>
                  {/* <ul className="team-social">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* team area end */}
      </div>
    );
  }
}

export default Team;
