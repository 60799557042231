import axios from "axios";
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENT,
  ERROR_DEPARTMENT,
  URL,
} from "../actions";

// GET Departments
export const getDepartments = () => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/departments`);
    dispatch({
      type: GET_DEPARTMENTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_DEPARTMENT,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
// GET Departments
export const getoneDepartment = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/${id}`);
    dispatch({
      type: GET_DEPARTMENT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_DEPARTMENT,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
