import React from "react";
import {
  visionTitle,
  visionDes,
  messageDes,
  messageTitle,
  ValuablesDes,
  ValuablesTitle,
} from "../global-components/sections";

const Intro = ({ lang }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="intro-area pd-top-50"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div
              className="single-intro wow animated fadeInUp"
              data-wow-duration="0.6s"
              data-wow-delay="0.1s"
            >
              <div
                className={`single-intro style-two ${
                  lang === "ar" ? "text-right" : "text-left"
                }`}
              >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/icons/30.webp"}
                    alt="img"
                    width="70"
                  />
                </div>
                <h3 className="intro-title"> {visionTitle[lang]}</h3>
                <p className="text-justify">{visionDes[lang]}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="single-intro wow animated fadeInUp"
              data-wow-duration="1.0s"
              data-wow-delay="0.2s"
            >
              <div
                className={`single-intro style-two ${
                  lang === "ar" ? "text-right" : "text-left"
                }`}
              >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/icons/31.png"}
                    alt="img"
                    width="70"
                  />
                </div>
                <h4 className="intro-title"> {messageTitle[lang]}</h4>
                <p className="text-justify">{messageDes[lang]}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className="single-intro wow animated fadeInUp"
              data-wow-duration="1.6s"
              data-wow-delay="0.3s"
            >
              <div
                className={`single-intro style-two ${
                  lang === "ar" ? "text-right" : "text-left"
                }`}
              >
                <div className="thumb">
                  <img
                    src={publicUrl + "assets/img/icons/32.png"}
                    alt="img"
                    width="70"
                  />
                </div>
                <h4 className="intro-title">{ValuablesTitle[lang]}</h4>
                <p className="text-justify">{ValuablesDes[lang]}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
