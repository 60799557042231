import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import AdvsDetails from "./blog-components/adv-details";
import Footer from "./global-components/footer";
import { useDispatch, useSelector } from "react-redux";
import { getOneAdv } from "../redux/actions/advs.action";

const AdvsDetailsPage = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneAdv(match.params.id));
  }, [match]);
  const { adv } = useSelector((state) => state.advs);

  return (
    adv && (
      <div>
        <Navbar />
        <PageHeader headertitle={adv && adv.title} />
        <AdvsDetails advs={adv} />
        <Footer />
      </div>
    )
  );
};

export default AdvsDetailsPage;
