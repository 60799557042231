import React from "react";
import moment from "moment";
import Sidebar from "./sidebar";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const NewsDetails = ({ news }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const shareUrl = "https://hipz.edu.ly/#/";
  return (
    news && (
      <div className="blog-details-area pd-top-120 viaje-go-top rtl">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-blog mb-0">
                <div className="thumb">
                  <img
                    src={"http://portal.hipz.edu.ly/" + news.image}
                    alt={news.title}
                  />
                </div>
                <div className="single-blog-details text-right">
                  <p className="date mb-0">
                    {moment(news.updatedAt).format("ll")}
                  </p>
                  <h3 className="title">{news.title}</h3>
                  <p
                    className="content mb-0 text-justify"
                    dangerouslySetInnerHTML={{ __html: news.discription }}
                  ></p>
                </div>
              </div>
              {/* Share  */}
              <h5 className="text-center"> لمشاركة الخبر</h5>
              <div className="text-center">
                <FacebookShareButton
                  url={`${shareUrl}news/${news._id}`}
                  quote={news && news.title}
                  hashtag="#اخبار المعهد  العالي
				  لعلوم البترول و الغاز الطبيعي بالزاوية
				  "
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <EmailShareButton
                  url={`${shareUrl}news/${news._id}`}
                  subject={news && news.title}
                  body={news && news.description}
                >
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
                <LinkedinShareButton url={`${shareUrl}news/${news._id}`}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={`${shareUrl}news/${news._id}`}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <TwitterShareButton
                  url={`${shareUrl}news/${news._id}`}
                  title={news && news.title}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <ViberShareButton
                  url={`${shareUrl}news/${news._id}`}
                  title={news && news.title}
                >
                  <ViberIcon size={40} round={true} />
                </ViberShareButton>
                <WhatsappShareButton
                  url={`${shareUrl}news/${news._id}`}
                  title={news && news.title}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
              {/* details-gallery-start */}
              <div className="gallery-area ">
                <div className="containerss">
                  <div className="row custom-gutter ">
                    {/* gallery-item */}
                    {news.imagegalary.length > 0 &&
                      news.imagegalary.map((image) => (
                        <div
                          className="tp-gallery-item col-md-4 col-sm-6"
                          style={{ right: "0% !important" }}
                        >
                          <div className="tp-gallery-item-img">
                            <img
                              src={"http://portal.hipz.edu.ly" + image}
                              alt={news.title}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* blog-comment-area start */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    )
  );
};

export default NewsDetails;
