import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";
import HomeV2 from "./components/home-v2";
import AboutUs from "./components/about";
import AdvsDetails from "./components/advs-details";
import NewsDetails from "./components/news-details";

import TourList from "./components/tour-list";
import TourListV2 from "./components/tour-list-v2";
import TourListV3 from "./components/tour-list-v3";
import TourDetails from "./components/tour-details";
import NewsLIst from "./components/news-list";
import DestinationLIstV2 from "./components/destination-list-v2";
import DestinationDetails from "./components/destination-details";
import Gallery from "./components/gallery";
import GalleryDetails from "./components/gallery-details";
import Faq from "./components/faq";
import Contact from "./components/contact";
import Error from "./components/error";
import CommingSoon from "./components/comming-soon";
import UserProfile from "./components/user-profile";
import Blog from "./components/blog";
import BlogV2 from "./components/blog-v2";
import BlogV3 from "./components/blog-v3";
import { Provider } from "react-redux";
import store from "./store";

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter basename="/">
          <div>
            <Switch>
              <Route exact path="/" component={HomeV2} />
              <Route path="/about" component={AboutUs} />
              <Route path="/advs/:id" component={AdvsDetails} />
              <Route path="/advs" component={TourList} />
              <Route path="/news/:id" component={NewsDetails} />
              <Route path="/news" component={NewsLIst} />
              <Route
                path="/destination-list-v2"
                component={DestinationLIstV2}
              />
              <Route path="/departments/:id" component={DestinationDetails} />

              <Route path="/tour-list-v2" component={TourListV2} />
              <Route path="/tour-list-v3" component={TourListV3} />
              <Route path="/tour-details" component={TourDetails} />
              <Route path="/gallery" component={Gallery} />
              <Route path="/gallery-details" component={GalleryDetails} />
              <Route path="/faq" component={Faq} />
              <Route path="/contact" component={Contact} />
              <Route path="/error" component={Error} />
              <Route path="/comming-soon" component={CommingSoon} />
              <Route path="/user-profile" component={UserProfile} />
              <Route path="/blog" component={Blog} />
              <Route path="/blog-v2" component={BlogV2} />
              <Route path="/blog-v3" component={BlogV3} />
            </Switch>
          </div>
        </HashRouter>
      </Provider>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("viaje"));
