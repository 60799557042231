import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Contact extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return	<div
    style={{ direction: "rtl" }}
    >
              <div className="contact-area pd-top-108">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="section-title text-sm-center ">
                        <h2 className="title">تواصلوا معنا</h2>
                        <p>لا تتردد في الاتصال بنا للحصول على اي استفسار او معلومات </p>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    
                      <div className="col-lg-6 ">
                        <img src={publicUrl+"assets/img/others/111.png"} alt="img" />
                      
                    </div>
                    {/* <div className="col-xl-5 col-lg-6">
                      <form className="tp-form-wrap">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Name</span>
                              <input type="text" name="name" />
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Number</span>
                              <input type="text" name="number"/>
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Email</span>
                              <input type="text" name="email"/>
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <label className="single-input-wrap style-two">
                              <span className="single-input-title">Message</span>
                              <textarea defaultValue={""} name="message" />
                            </label>
                          </div>
                          <div className="col-12">
                            <input type="submit" className="btn btn-yellow" value="Send Message" />
                          </div>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="contact-info-area pd-top-120">
                <div className="container">
                  <div className="row justify-content-center text-right">
                    <div className="col-xl-7 col-lg-8 order-lg-12">
                      <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3355.3620441784788!2d12.73989438552143!3d32.75612669235884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a921bdeb089819%3A0x3128b60836e9f9c!2z2YXYr9ix2LPYqSDYp9mE2LTZitio2KfZhtmKINio2YYg2YbYtdix2KfYqg!5e0!3m2!1sar!2sly!4v1653359263578!5m2!1sar!2sly" />

                     
                    </div>
                    <div className="col-xl-3 col-lg-4 order-lg-1">
                      <div className="contact-info bg-gray">
                        <p>
                          <i className="fa fa-map-marker" /> 
                          <span>                      الزاوية - شارع جمال عبد الناصر -مدرسة الشيباني بن نصرات
</span>
                        </p>
                        <p>
                          <i className="fa fa-clock-o" /> 
                          <span>ساعات العمل 9:00 الى 7:00 السبت 10:00 الى 5:00</span>
                        </p>
                        <p>
                          <i className="fa fa-envelope" /> 
                          <span>البريد الالكتروني: <span>info@hipz.edu.ly</span></span>
                        </p>
                        <p>
                          <i className="fa fa-phone" /> 
                          <span>
                            هاتف 1: <span>0927630338</span> <br />
                            هاتف 2: <span>0946750314</span> <br />
                            هاتف 3: <span>0917419804</span> <br />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        }
}

export default Contact