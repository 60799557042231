import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembersByDepartment } from "../../redux/actions/Members.actions";

const Members = ({ department }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  useEffect(() => {
    department && dispatch(getMembersByDepartment(department._id));
  }, [department]);
  const { members } = useSelector((state) => state.members);
  return (
    <div
      className="team-newslater-bg mt-4"
      style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/4.png)" }}
    >
      {/* team area end */}
      <div className="team-area pd-top-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="title">
                  اعضاء هيئة التدريس بقسم {department && department.title}{" "}
                </h2>
                <p>
                  نخبة مميزة من اعضاء هيئة التدريس لنقل المعارف والمهارات
                  المطلوبة للطالب
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ direction: "rtl" }}>
            {members &&
              members.map((member) => (
                <div className="col-lg-3 col-sm-6" key={member._id}>
                  <div className="single-team text-center">
                    <div className="thumb">
                      <img
                        src={
                          member.image
                            ? "http://portal.hipz.edu.ly" + member.image
                            : publicUrl + "assets/img/team/3.png"
                        }
                        alt="team"
                      />
                    </div>
                    <h3 className="name">
                      <a href="#">{member.name}</a>
                    </h3>
                    <span>عضوء هيئة تدريس</span>
                    <ul className="team-social">
                      <li>
                        <a href="#">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* team area end */}
    </div>
  );
};

export default Members;
