import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Sidebar from "./sidebar";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberIcon,
  ViberShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const advsDetails = ({ advs }) => {
  const shareUrl = "https://hipz.edu.ly/#/";
  return (
    advs && (
      <div className="blog-details-area pd-top-120 viaje-go-top rtl ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-blog mb-0">
                <div className="thumb text-right">
                  <img
                    src={"http://portal.hipz.edu.ly/" + advs.image}
                    alt={advs.title}
                  />
                </div>
                <div className="single-blog-details text-right">
                  <p className="date mb-0">
                    {moment(advs.updatedAt).format("ll")}
                  </p>
                  <h3 className="title">{advs.title}</h3>
                  <p
                    className="content mb-0 text-justify"
                    dangerouslySetInnerHTML={{ __html: advs.discription }}
                  ></p>
                </div>
              </div>
              {/* Share  */}
              <h5 className="text-center"> لمشاركة الاعلان</h5>
              <div className="text-center">
                <FacebookShareButton
                  url={`${shareUrl}advs/${advs._id}`}
                  quote={advs && advs.title}
                  hashtag="#اعلانات المعهد  العالي
				  لعلوم البترول و الغاز الطبيعي بالزاوية
				  "
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <EmailShareButton
                  url={`${shareUrl}advs/${advs._id}`}
                  subject={advs && advs.title}
                  body={advs && advs.description}
                >
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
                <LinkedinShareButton url={`${shareUrl}advs/${advs._id}`}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={`${shareUrl}advs/${advs._id}`}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <TwitterShareButton
                  url={`${shareUrl}advs/${advs._id}`}
                  title={advs && advs.title}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <ViberShareButton
                  url={`${shareUrl}advs/${advs._id}`}
                  title={advs && advs.title}
                >
                  <ViberIcon size={40} round={true} />
                </ViberShareButton>
                <WhatsappShareButton
                  url={`${shareUrl}advs/${advs._id}`}
                  title={advs && advs.title}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
              </div>
              {/* details-gallery-start */}
              <div className="gallery-area ">
                <div className="containerss">
                  <div className="row custom-gutter ">
                    <div className="gallery-sizer col-1" />

                    {/* gallery-item */}
                    {advs.imagegalary.length > 0 &&
                      advs.imagegalary
                        .slice(1, advs.imagegalary.length - 1)
                        .map((image) => (
                          <div
                            className="tp-gallery-item col-md-4 col-sm-6"
                            style={{ right: "0% !important" }}
                          >
                            <div className="tp-gallery-item-img">
                              <img
                                src={"http://portal.hipz.edu.ly" + image}
                                alt={advs.title}
                              />
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>

              {/* blog-comment-area start */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    )
  );
};

export default advsDetails;
