import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllAdvs } from "../../redux/actions/advs.action";

const HolidayFun = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllAdvs());
  }, []);
  const { advs } = useSelector((state) => state.advs);
  return (
    <div
      className="holiday-plan-area tp-holiday-plan-area mg-top-96"
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/8.png)",
        direction: "rtl",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-9">
            <div className="section-title text-center">
              <h2
                className="title wow animated fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.1s"
              >
                {" "}
                اخر الاعلانات
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          {advs &&
            advs.slice(0, 3).map((n) => (
              <div className="col-lg-4">
                <div className="single-destinations-list style-four rtl">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage: `url(http://portal.hipz.edu.ly/${n.image}`,
                    }}
                    src={"http://portal.hipz.edu.ly/" + n.image}
                  />
                  <div className="details text-right">
                    <h4 className="title">
                      <Link to={`/advs/${n._id}`}>{n.title}</Link>
                    </h4>
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{ __html: n.discription }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <h3 className=" text-center">
          <Link className="btn btn-gray text-right" to="/advs">
            المزيد من الاعلانات
            <i className="la la-arrow-left" />
          </Link>
        </h3>
      </div>
    </div>
  );
};

export default HolidayFun;
