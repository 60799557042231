import React, { Component } from "react";
import { Link } from "react-router-dom";
import { What, More } from "../global-components/sections";
const Video = ({ lang }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="video-area tp-video-area pd-top-110"
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/7.png)",
        direction: lang === "ar" ? "rtl" : "ltr",
      }}
    >
      <div className="container viaje-go-top">
        <div className="row">
          <div
            className="col-xl-5 col-lg-6 align-self-center wow animated fadeInRight"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div
              className={`section-title mb-lg-0 mb-4 text-center 
            
              ${lang === "ar" ? "text-lg-right" : "text-lg-left"}  
            `}
            >
              <div dangerouslySetInnerHTML={{ __html: What[lang] }}></div>
              <Link
                className={`btn btn-yellow ${
                  lang === "ar" ? "text-right" : "text-left"
                }`}
                to="/about"
              >
                <span>
                  {More[lang]}
                  <i
                    className={
                      lang === "ar" ? "la la-arrow-left" : "la la-arrow-right"
                    }
                  />
                </span>
              </Link>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-6 offset-xl-1 wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="video-popup-wrap">
              <div className="thumb">
                <img src={publicUrl + "assets/img/video.png"} alt="video" />
              </div>
              <div className="video-popup-btn">
                <a
                  href="https://www.youtube.com/watch?v=lMhkXZIo22k"
                  className="video-play-btn mfp-iframe"
                >
                  <i className="fa fa-play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
