import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdvs } from "../../redux/actions/advs.action";

const DestinatioDetails = ({ department }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvs(department._id));
  }, []);
  const { advs } = useSelector((state) => state.advs);

  return (
    <div>
      <div className="destinations-details-page mg-top--70">
        <div className="container">
          {/* destinations-details-main-slider start */}

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="destinations-details-main-slider-wrap">
                <div className="destinations-details-main-slider">
                  <div className="d-details-main-slider-item">
                    <img
                      src={publicUrl + "assets/img/others/3.png"}
                      alt="img"
                    />
                  </div>
                  <div className="d-details-main-slider-item">
                    <img
                      src={publicUrl + "assets/img/others/2.png"}
                      alt="img"
                    />
                  </div>
                  <div className="d-details-main-slider-item">
                    <img
                      src={publicUrl + "assets/img/others/4.png"}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="destinations-details-main-slider-controls">
                  <div className="slider-nav tp-control-nav" />
                  {/*slider-nav*/}
                  <div className="slider-extra tp-slider-extra">
                    <div className="text">
                      <span className="first">01 </span>
                      <span className="last">05</span>
                    </div>
                    {/*text*/}
                    <div
                      className="d-list-progress"
                      role="progressbar"
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <span className="slider__label sr-only" />
                    </div>
                  </div>
                  {/*slider-extra*/}
                </div>
              </div>
            </div>
          </div>
          {/* destinations-details-main-slider End */}
          <div
            className="row destinations-details-location-name text-right"
            style={{ direction: "rtl" }}
          >
            <div className="col-lg-12">
              <h3>عن قسم {department && department.title}</h3>
            </div>
            <div className="col-lg-12 ">
              <p
                dangerouslySetInnerHTML={{
                  __html: department && department.description,
                }}
              />
            </div>
          </div>
          {/* destinations-client-review-slider start */}
          <h4 className="single-page-small-title text-right">اعلانات القسم</h4>
          <div className="destinations-client-review-slider tp-common-slider-style">
            {advs.map((adv) => (
              <div className="d-client-review-slider-item" key={adv._id}>
                <div className="single-destination-grid text-center">
                  <div className="thumb">
                    <img
                      src={"http://portal.hipz.edu.ly/" + adv.image}
                      alt="img"
                    />
                  </div>
                  <div className="details">
                    <h4 className="title">{adv.title}</h4>
                    <p
                      className="content text-right"
                      dangerouslySetInnerHTML={{ __html: adv.discription }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* location-details start */}
          <div
            className="location-details text-right"
            style={{ direction: "rtl" }}
          >
            <h4 className="single-page-small-title">بيانات القسم</h4>
            <div className="row">
              <div className="col-lg-12">
                <div className="location-details-table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="title">لغة الدراسة</td>
                        <td>{department && department.languege}</td>
                      </tr>
                      <tr>
                        <td className="title">البريد الالكتروني</td>
                        <td>{department && department.email} </td>
                      </tr>
                      <tr>
                        <td className="title">مدة الدراسة</td>
                        <td>6 فصول</td>
                      </tr>
                      <tr>
                        <td className="title">صفة المتخرج</td>
                        <td>مهندس</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="btn-wrap text-center">
                    <a
                      target="_blank"
                      className="btn btn-yellow"
                      href={`https://sis.hipz.edu.ly/uploads/subjects/${
                        department && department.Url
                      }.pdf`}
                    >
                      <span>الخطة الدراسية</span>
                      <i className="ti-download" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinatioDetails;
