import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import NewsDetails from "./blog-components/blog-details";
import Footer from "./global-components/footer";
import { useDispatch, useSelector } from "react-redux";
import { getOneNews } from "../redux/actions/news.action";

const NewsDetailsPage = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneNews(match.params.id));
  }, [match]);
  const { news } = useSelector((state) => state.news);

  return (
    news && (
      <div>
        <Navbar />
        <PageHeader headertitle={news && news.title} />
        <NewsDetails news={news} />
        <Footer />
      </div>
    )
  );
};

export default NewsDetailsPage;
