import React, { Component } from "react";
import { Director, boss } from "../global-components/sections";
const Review = ({ lang }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="client-review-area client-review-area-home pd-top-70"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            <div
              className="thumb wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="client-review-thumb">
                <img
                  src={publicUrl + "assets/img/others/client-review.png"}
                  alt="review"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className={
                lang === "ar"
                  ? "single-client-review wow animated fadeInLeft text-right"
                  : "single-client-review wow animated fadeInLeft text-left"
              }
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div dangerouslySetInnerHTML={{ __html: Director[lang] }}></div>
              <div className="media">
                <div className="media-left">
                  <img
                    src={publicUrl + "assets/img/client/1.png"}
                    alt="client"
                  />
                </div>
                <div
                  className="media-body"
                  dangerouslySetInnerHTML={{ __html: boss[lang] }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
