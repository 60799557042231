import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/video-banner";
import Search from "./section-components/search";
import Intro from "./section-components/intro";
import Offer from "./section-components/offer";
import About from "./section-components/video";
import News from "./section-components/News-Section";
import Advs from "./section-components/Advs-Section";
import Review from "./section-components/review";
import BlogSection from "./blog-components/blog-section";
import Ads from "./section-components/ads";
import Footer from "./global-components/footer";
import "moment/locale/ar-ly";
import { useSelector } from "react-redux";

const Home_V2 = () => {
  const { lang } = useSelector((state) => state.advs);
  return (
    <div>
      <Navbar />
      <Banner lang={lang} />
      <Intro lang={lang} />
      {lang === "ar" && <Advs />}
      <About lang={lang} />
      {lang === "ar" && <News />}
      <Review lang={lang} />
      {/* <Offer /> */}

      <BlogSection />
      <Ads />
      <br />
      <Footer lang={lang} />
    </div>
  );
};

export default Home_V2;
