import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNews } from "../../redux/actions/news.action";
import { useSelector } from "react-redux";

const DestinationList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
  }, []);
  const { newss } = useSelector((state) => state.news);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="destination-area viaje-go-top">
      <div className="container-bg mg-top--70">
        <div className="container">
          <div className="row justify-content-center">
            {newss &&
              newss.map((news) => (
                <div className="col-lg-4 col-md-6">
                  <div className="single-destination-grid rtl">
                    <div className="thumb">
                      <Link to={`/news/${news._id}`}>
                        <img
                          src={"http://portal.hipz.edu.ly/" + news.image}
                          alt={news.title}
                        />
                      </Link>
                    </div>
                    <div className="details text-right">
                      <Link to={`/news/${news._id}`}>
                        <h3 className="title">{news.title}</h3>
                      </Link>
                      <p
                        className="content text-justify"
                        dangerouslySetInnerHTML={{
                          __html: news.discription.substr(0, 200),
                        }}
                      ></p>
                      <Link className="btn btn-gray" to={`/news/${news._id}`}>
                        تفاصيل الخبر
                        <i className="la la-arrow-left" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

            {/* <div className="col-12">
              <div className="btn-wrapper text-center">
                <Link className="btn btn-yellow mt-4" to="/destination-list-v2">
                  <span>
                    تحميل باقي الاخبار
                    <i className="la la-arrow-right" />
                  </span>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationList;
