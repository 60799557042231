import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/actions/news.action";
import moment from "moment";

const Sidebar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
  }, []);
  const { newss } = useSelector((state) => state.news);

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="col-lg-4">
      <aside className="sidebar-area sidebar-area-4 ">
        <div className="widget widget-recent-post ">
          <h2 className="widget-title text-right">اخر الاخبار</h2>
          <ul>
            {newss &&
              newss.slice(0, 6).map((news) => (
                <li>
                  <div className="media">
                    <img
                      src={"http://portal.hipz.edu.ly/" + news.image}
                      alt={news.title}
                    />
                    <div className="media-body text-right mr-2">
                      <span className="post-date">
                        {moment(news.updatedAt).format("ll")}
                      </span>
                      <Link to={`/news/${news._id}`}>
                        <h6 className="title">{news.title}</h6>
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
