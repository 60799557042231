import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../redux/actions/department.action";
import { ChangeLang } from "../../redux/actions/advs.action";
import { Main, About, Departments, Contact, platform } from "./sections";
const Navbar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartments());
  }, []);
  const { departments } = useSelector((state) => state.departments);
  const { lang } = useSelector((state) => state.advs);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  console.log(lang);
  return (
    <nav
      className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div className="container nav-container">
        <div className="responsive-mobile-menu">
          <div className="mobile-logo">
            <Link to="/">
              <img
                src={publicUrl + "assets/img/sticky-logo.png"}
                alt={imgattr}
              />
            </Link>
          </div>
          <button
            className="navbar-toggler float-right"
            type="button"
            data-toggle="collapse"
            data-target="#tp_main_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggle-icon">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </span>
          </button>
          <div className="nav-right-content">
            <ul className="pl-0">
              <li className="search">
                <i className="ti-search" />
              </li>
              <li className="notification">
                <a className="signUp-btn" href="#">
                  <i className="fa fa-user-o" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="tp_main_menu">
          <div className="logo-wrapper desktop-logo">
            <Link to="/" className="main-logo">
              <img src={publicUrl + "assets/img/logo.png"} alt="logo" />
            </Link>
            <Link to="/" className="sticky-logo">
              <img src={publicUrl + "assets/img/sticky-logo.png"} alt="logo" />
            </Link>
          </div>
          <ul className="navbar-nav">
            <li>
              <Link to="/">{Main[lang]}</Link>
            </li>
            <li>
              <Link to="/about">{About[lang]} </Link>
            </li>
            <li className="menu-item-has-children">
              <Link to="/blog">{Departments[lang]}</Link>
              <ul className="sub-menu">
                {departments &&
                  departments.map((department) => (
                    <li>
                      <Link to={`/departments/${department.Url}`}>
                        قسم {department.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </li>
            <li>
              <Link to="/contact">{Contact[lang]}</Link>
            </li>
          </ul>
        </div>
        <div className="nav-right-content">
          <ul>
            <li>
              <a
                className="btn btn-yellow"
                href="http://portal.hipz.edu.ly/"
                target="_blank"
              >
                {platform[lang]}
                <i className="fa fa-paper-plane" />
              </a>
            </li>

            <li>
              <a
                href="#"
                className="theme-btn btn-style-one"
                onClick={() => dispatch(ChangeLang("ar"))}
              >
                <span className="btn-title">AR</span>
              </a>
              {/* eslint-disable-next-line  */}
              <a
                href="#"
                className="theme-btn btn-style-one"
                onClick={() => dispatch(ChangeLang("en"))}
                style={{ margin: "0.4rem" }}
              >
                <span className="btn-title">EN</span>
              </a>{" "}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
