import React, { Component } from "react";
import parse from "html-react-parser";
import {
  intro3,
  classes,
  Tools,
  ELearning,
} from "../global-components/sections";

const IntroV3 = ({ lang }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="intro-area pd-top-108"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <div className="container">
        <div
          className={
            lang === "ar"
              ? "section-title text-lg-center text-right"
              : "section-title text-lg-center text-left"
          }
          dangerouslySetInnerHTML={{ __html: intro3[lang] }}
        ></div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className={
                lang === "ar"
                  ? "single-intro style-two text-right"
                  : "single-intro style-two text-left"
              }
            >
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/9.png"}
                  alt="img"
                  width="70"
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: classes[lang] }}></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className={
                lang === "ar"
                  ? "single-intro style-two text-right"
                  : "single-intro style-two text-left"
              }
            >
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/10.png"}
                  alt="img"
                  width="70"
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: Tools[lang] }}></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className={
                lang === "ar"
                  ? "single-intro style-two text-right"
                  : "single-intro style-two text-left"
              }
            >
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/11.png"}
                  alt="img"
                  width="70"
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: ELearning[lang] }}></div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className={
                lang === "ar"
                  ? "single-intro style-two text-right"
                  : "single-intro style-two text-left"
              }
            >
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/icons/12.png"}
                  alt="img"
                  width="70"
                />
              </div>
              <h4 className="intro-title">آعضاءهيئة التدريس</h4>
              <p className="text-justify">
                يتعاون بالمعهد مجموعة من اعضاء هيئة التدريس دوي الكفاءة و الخبرة
                العالية في مجال التدريس بالطرق الحديثة
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroV3;
