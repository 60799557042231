import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Intro from "./section-components/intro-v3";
import About from "./section-components/about";
import Team from "./section-components/team";
import Footer from "./global-components/footer";
import { useSelector } from "react-redux";

const AboutPage = () => {
  const { lang } = useSelector((state) => state.advs);

  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle={lang === "ar" ? "عن المعهد" : " About the  institute "}
      />
      <Intro lang={lang} />
      <About lang={lang} />
      <Team />
      <Footer />
    </div>
  );
};

export default AboutPage;
